import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/PickTypeHome/TopQuote"
import HomeType from "../components/PickTypeHome/HomeType"
import Callout from "../components/PickTypeHome/Callout"

const PickTypeHome = props => {
  const {
    seoInfo,
    topQuote,
    homeTypePicked,
    allHomes,
    allDetails,
    allCost,
    allBuilders,
    allTypes,
    callOut,
  } = props.data

  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <HomeType
        picked={homeTypePicked}
        allHomes={allHomes}
        allDetails={allDetails}
        allCost={allCost}
        allBuilders={allBuilders}
        allTypes={allTypes}
      />
      <Callout data={callOut} />
    </Layout>
  )
}

export const pickTypeHomeQuery = graphql`
  query pickTypeHomePage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    homeTypePicked: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_pyht_home_type {
          name
        }
      }
    }

    allHomes: allWordpressWpHomes {
      edges {
        node {
          wordpress_id
          title
          home_type
          home_details
          builder
          cost
          slug
          acf {
            _coo_hopst_homes_excerpt
            _coo_hopst_home_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allDetails: allWordpressWpHomeDetails {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allCost: allWordpressWpCost {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allBuilders: allWordpressWpBuilder {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allTypes: allWordpressWpHomeType {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }

    callOut: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hoca_top_title
        _coo_hoca_top_content
        _coo_hoca_top_btn_text
        _coo_hoca_top_btn_link_two
        _coo_hoca_top_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hoca_bg_color
      }
    }
  }
`

export default PickTypeHome
