const detailsFilter = (currentDetail, allDetails) => {
  const detailsMatched = allDetails.filter(det => {
    if (det.node.wordpress_id === currentDetail) return true
    return false
  })
  const detailNames = detailsMatched.map(det => det.node.name)
  return detailNames
}

export default detailsFilter
