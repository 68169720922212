const builderFilter = (currentBuilder, allBuilders) => {
  const buildersMatched = allBuilders.filter(build => {
    if (build.node.wordpress_id === currentBuilder) return true
    return false
  })
  const builderNames = buildersMatched.map(build => build.node.name)
  return builderNames
}

export default builderFilter
