import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import HomeCard from "../Shared/HomeCard"
import { medWrapper, buttonThree } from "../../styles/helpers"

const HomesWrapper = styled.div`
  .wrapper {
    ${medWrapper};
  }

  .goBack {
    width: 100%;
    padding: 2rem;
    text-align: center;

    a {
      ${buttonThree};
    }
  }
`

const HomeType = props => {
  const { picked, allHomes, allDetails, allCost, allBuilders, allTypes } = props

  const attachedTypeId = allTypes.edges.find(
    type => type.node.name === picked.acf._coo_pyht_home_type.name
  ).node.wordpress_id

  const allHomesDisplayed = allHomes.edges.filter(home => {
    return home.node.home_type[0] === attachedTypeId
  })

  allHomesDisplayed.sort(
    (a, b) => a.node.home_details[0] - b.node.home_details[0]
  )

  return (
    <HomesWrapper>
      <div className="wrapper">
        {allHomesDisplayed.map((home, index) => {
          return (
            <HomeCard
              key={index}
              home={home}
              types={allTypes}
              details={allDetails}
              builders={allBuilders}
              cost={allCost}
            />
          )
        })}
      </div>
      <div className="goBack">
        <Link to="/pick-your-home/#perfectHome">go back to all homes</Link>
      </div>
    </HomesWrapper>
  )
}

export default HomeType
