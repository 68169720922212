import React from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"
import { Link } from "gatsby"

import detailsFilter from "../PickYourHome/Filters/detailsFilter"
import typeFilter from "../PickYourHome/Filters/typeFilter"
import builderFilter from "../PickYourHome/Filters/builderFilter"
import costFilter from "../PickYourHome/Filters/costFilter"

import { colors, B1Black, fontSizer, H4Black } from "../../styles/helpers"

const costAverager = cos => {
  let stratingFrom = ""
  if (cos <= 374) {
    stratingFrom = "mid $300"
  } else if (cos > 374 && cos <= 399) {
    stratingFrom = "high $300"
  } else if (cos > 399 && cos <= 424) {
    stratingFrom = "low $400"
  } else if (cos > 424 && cos <= 474) {
    stratingFrom = "mid $400"
  } else if (cos > 474 && cos <= 499) {
    stratingFrom = "high $400"
  } else if (cos > 499 && cos <= 524) {
    stratingFrom = "low $500"
  } else if (cos > 524 && cos <= 574) {
    stratingFrom = "mid $500"
  } else if (cos > 574 && cos <= 599) {
    stratingFrom = "high $500"
  } else if (cos > 599 && cos <= 624) {
    stratingFrom = "low $600"
  } else if (cos > 624 && cos <= 674) {
    stratingFrom = "mid $600"
  } else if (cos > 674 && cos <= 699) {
    stratingFrom = "high $600"
  } else if (cos > 699) {
    stratingFrom = "$700"
  }

  return stratingFrom
}

const SingleHomeDisplay = styled.div`
  margin: 2rem auto;
  background-color: ${colors.grey};

  @media (min-width: 768px) {
    width: calc(33.33% - 4rem);
    margin: 2rem;
  }

  .homeImage {
    position: relative;
    width: 100%;
    min-height: 27.5rem;

    div {
      width: 100%;
      height: 100%;
      position: static !important;
      background-position: bottom center;
      background-repeat: repeat;
      background-size: cover;
      z-index: -1;
    }
  }

  .homeType {
    padding: 2rem 4rem 1rem;

    &.AttachedHomes {
      border-top: 0.3rem solid ${colors.colorShad};
    }

    &.SingleFamilyHomes {
      border-top: 0.3rem solid ${colors.colorSecondary};
    }

    &.EstateHomes {
      border-top: 0.3rem solid ${colors.colorPrimary};
    }

    p {
      ${H4Black};
      margin-bottom: 0;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .homeDetails {
    padding: 0 4rem;
    p {
      ${H4Black};
      margin-top: 0;
      margin-bottom: 1rem;
      font-weight: 300;
    }
  }

  .homeBuilder {
    padding: 0 4rem;
    p {
      ${B1Black};
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .homeCost {
    padding: 0 4rem;
    border-bottom: solid 0.2rem ${colors.colorSecondary};

    &.AttachedHomes {
      border-bottom: 0.3rem solid ${colors.colorShad};
    }

    &.SingleFamilyHomes {
      border-bottom: 0.3rem solid ${colors.colorSecondary};
    }

    &.EstateHomes {
      border-bottom: 0.3rem solid ${colors.colorPrimary};
    }
    p {
      ${B1Black};
      color: ${colors.colorShad};

      a {
        ${B1Black};
        ${fontSizer(1.4, 1.4, 76.8, 150, 1.6)};

        &:hover {
          color: ${colors.colorPrimary};
        }
      }
    }
  }

  .homeExcerpt {
    padding: 2rem 4rem;
    p {
      ${B1Black};
      margin-bottom: 0;
    }
  }

  .homeLink {
    padding: 2rem 4rem;

    a {
      ${B1Black};
      ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
      text-transform: uppercase;

      span {
        padding-right: 0.5rem;
      }
    }
  }
`

const HomeCard = ({ home, types, details, builders, cost }) => {
  const typeName = types.edges.find(allType => {
    const thisHomeTyep = home.node.home_type.find(type => {
      if (type === allType.node.wordpress_id) return type
    })

    if (thisHomeTyep) return true
  })

  const typeClass = typeName.node.name.split(" ").join("")

  return (
    <SingleHomeDisplay>
      <div className="homeImage">
        <BGImage
          tag="div"
          fluid={
            home?.node?.acf?._coo_hopst_home_image?.localFile?.childImageSharp
              ?.fluid
          }
        />
      </div>
      <div className={`homeType ${typeClass}`}>
        <p>
          {home.node.home_type.map((type, index) => (
            <span key={index}>
              {typeFilter(type, types.edges).map((ty, i) => (
                <span key={i}>{ty}</span>
              ))}
            </span>
          ))}
        </p>
      </div>

      <div className="homeDetails">
        <p>
          {home.node.home_details.map((detail, index) => (
            <span key={index}>
              {detailsFilter(detail, details.edges).map((det, i) => (
                <span key={i}>{`${det} `}</span>
              ))}
            </span>
          ))}
        </p>
      </div>
      <div className="homeBuilder">
        <p>
          {home.node.builder.map((build, index) => (
            <span key={index}>
              {builderFilter(build, builders.edges).map((bud, i) => (
                <span key={i}>{bud} </span>
              ))}
            </span>
          ))}
        </p>
      </div>

      <div className={`homeCost ${typeClass}`}>
        <p>
          {/* Starting from the{" "}
          {home.node.cost.map((price, index) => (
            <span key={index}>
              {costFilter(price, cost.edges).map((cos, i) => {
                const stratingFrom = costAverager(cos)

                return <span key={i}>{stratingFrom}s</span>
              })}
            </span>
          ))} */}

          <Link to="/show-homes">Contact Show Homes for Pricing</Link>
        </p>
      </div>
      <div className="homeLink">
        <Link to={`/pick-your-home/${home.node.slug}`}>
          <span>&gt;</span>View More
        </Link>
      </div>
    </SingleHomeDisplay>
  )
}

export default HomeCard
