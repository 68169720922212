import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { medWrapper, buttonThree, H1Green, B1Black } from "../../styles/helpers"

const CallOutSection = styled.section`
  margin: 5rem auto;

  .calloutTop {
    width: 100%;
    padding: 4rem 0;

    &__wrapper {
      ${medWrapper};
      align-items: center;
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(55% - 4rem);
        margin: 0 2rem;
      }

      @media (min-width: 1025px) {
        width: calc(55% - 4rem);
        margin: 0 2rem;
      }

      h2 {
        ${H1Green};
      }

      p {
        ${B1Black};
      }

      a {
        ${buttonThree};
      }
    }

    &__image {
      width: 100%;
      margin: 3rem auto 0;

      @media (min-width: 768px) {
        width: calc(45% - 4rem);
        margin: 0 2rem;
      }

      @media (min-width: 1025px) {
        width: calc(45% - 4rem);
        margin: 0 2rem;
      }
    }
  }
`

const CallOut = ({ data }) => {
  const topTitle = data.acf._coo_hoca_top_title
  const topContent = data.acf._coo_hoca_top_content
  const topBtnText = data.acf._coo_hoca_top_btn_text
  const topBtnSlug = data.acf._coo_hoca_top_btn_link_two
  const topImg = data.acf._coo_hoca_top_image
  const bgColor = data.acf._coo_hoca_bg_color

  const topSlugLink = topBtnSlug
    .split("/")
    .filter(part => {
      if (
        part !== "" &&
        part !== "http:" &&
        part !== "https:" &&
        part !== "localhost"
      )
        return part
    })
    .filter((slug, index) => {
      if (index !== 0) return slug
    })
    .join("/")

  return (
    <CallOutSection bgColor={bgColor}>
      <div className="wrapper">
        <div className="calloutTop">
          <div className="calloutTop__wrapper">
            <div className="calloutTop__content">
              <div>
                <h2>{topTitle}</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: topContent }} />
              <div>
                <Link to={`/${topSlugLink}`}>{topBtnText}</Link>
              </div>
            </div>

            <div className="calloutTop__image">
              {topImg !== null && (
                <Img
                  fluid={topImg.localFile.childImageSharp.fluid}
                  alt={topImg.alt_text}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CallOutSection>
  )
}

export default CallOut
