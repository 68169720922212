const typeFilter = (currentType, allTypes) => {
  const typessMatched = allTypes.filter(type => {
    if (type.node.wordpress_id === currentType) return true
    return false
  })
  const typeNames = typessMatched.map(type => type.node.name)
  return typeNames
}

export default typeFilter
